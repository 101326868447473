
import state from './state';
import dispatcher from './dispatcher';


const exports = {
    state,
    dispatcher,
};

export default exports;
