
import networkspeed from './networkspeed';
import onlinestatus from './onlinestatus';
import store from './store';


const exports = {
    networkspeed,
    onlinestatus,
    store,
};

export default exports;
